import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ApiApproveCompanyByIdParams,
  ApiGetCompaniesParams,
  BackendErrorEntity,
  CompanyEntity,
  CreateCompanyDto,
  GetCompaniesResponseDto,
  UpdateCompanyDto,
  UploadCompanyRegistrationReportFileDto
} from '.././types'
import { fetchApiInstance } from '../../fetchApiInstance';
import type { ErrorType, BodyType } from '../../fetchApiInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const apiCreateCompany = (
    createCompanyDto: MaybeRef<CreateCompanyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      createCompanyDto = unref(createCompanyDto);
      
      return fetchApiInstance<CompanyEntity>(
      {url: `/companies`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createCompanyDto
    },
      options);
    }
  


export const getApiCreateCompanyMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateCompany>>, TError,{data: BodyType<CreateCompanyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiCreateCompany>>, TError,{data: BodyType<CreateCompanyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiCreateCompany>>, {data: BodyType<CreateCompanyDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiCreateCompany(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiCreateCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof apiCreateCompany>>>
    export type ApiCreateCompanyMutationBody = BodyType<CreateCompanyDto>
    export type ApiCreateCompanyMutationError = ErrorType<BackendErrorEntity>

    export const useApiCreateCompany = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateCompany>>, TError,{data: BodyType<CreateCompanyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiCreateCompany>>,
        TError,
        {data: BodyType<CreateCompanyDto>},
        TContext
      > => {

      const mutationOptions = getApiCreateCompanyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiGetCompanies = (
    params?: MaybeRef<ApiGetCompaniesParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      params = unref(params);
      
      return fetchApiInstance<GetCompaniesResponseDto>(
      {url: `/companies`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiGetCompaniesQueryKey = (params?: MaybeRef<ApiGetCompaniesParams>,) => {
    return ['companies', ...(params ? [params]: [])] as const;
    }

    
export const getApiGetCompaniesQueryOptions = <TData = Awaited<ReturnType<typeof apiGetCompanies>>, TError = ErrorType<BackendErrorEntity>>(params?: MaybeRef<ApiGetCompaniesParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetCompanies>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetCompaniesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetCompanies>>> = () => apiGetCompanies(params, requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetCompanies>>, TError, TData> 
}

export type ApiGetCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetCompanies>>>
export type ApiGetCompaniesQueryError = ErrorType<BackendErrorEntity>



export function useApiGetCompanies<TData = Awaited<ReturnType<typeof apiGetCompanies>>, TError = ErrorType<BackendErrorEntity>>(
 params?: MaybeRef<ApiGetCompaniesParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetCompanies>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetCompaniesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiGetCompany = (
    id: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
      
      return fetchApiInstance<CompanyEntity>(
      {url: `/companies/${id}`, method: 'GET'
    },
      options);
    }
  

export const getApiGetCompanyQueryKey = (id: MaybeRef<string | undefined | null>,) => {
    return ['companies',id] as const;
    }

    
export const getApiGetCompanyQueryOptions = <TData = Awaited<ReturnType<typeof apiGetCompany>>, TError = ErrorType<BackendErrorEntity>>(id: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetCompany>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetCompanyQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetCompany>>> = () => apiGetCompany(id, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetCompany>>, TError, TData> 
}

export type ApiGetCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetCompany>>>
export type ApiGetCompanyQueryError = ErrorType<BackendErrorEntity>



export function useApiGetCompany<TData = Awaited<ReturnType<typeof apiGetCompany>>, TError = ErrorType<BackendErrorEntity>>(
 id: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetCompany>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetCompanyQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiUpdateCompany = (
    id: MaybeRef<string | undefined | null>,
    updateCompanyDto: MaybeRef<UpdateCompanyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
updateCompanyDto = unref(updateCompanyDto);
      
      return fetchApiInstance<CompanyEntity>(
      {url: `/companies/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateCompanyDto
    },
      options);
    }
  


export const getApiUpdateCompanyMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateCompany>>, TError,{id: string;data: BodyType<UpdateCompanyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUpdateCompany>>, TError,{id: string;data: BodyType<UpdateCompanyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUpdateCompany>>, {id: string;data: BodyType<UpdateCompanyDto>}> = (props) => {
          const {id,data} = props ?? {};

          return  apiUpdateCompany(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUpdateCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof apiUpdateCompany>>>
    export type ApiUpdateCompanyMutationBody = BodyType<UpdateCompanyDto>
    export type ApiUpdateCompanyMutationError = ErrorType<BackendErrorEntity>

    export const useApiUpdateCompany = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateCompany>>, TError,{id: string;data: BodyType<UpdateCompanyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUpdateCompany>>,
        TError,
        {id: string;data: BodyType<UpdateCompanyDto>},
        TContext
      > => {

      const mutationOptions = getApiUpdateCompanyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiDeleteCompany = (
    id: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
      
      return fetchApiInstance<CompanyEntity>(
      {url: `/companies/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getApiDeleteCompanyMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteCompany>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiDeleteCompany>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiDeleteCompany>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  apiDeleteCompany(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiDeleteCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof apiDeleteCompany>>>
    
    export type ApiDeleteCompanyMutationError = ErrorType<BackendErrorEntity>

    export const useApiDeleteCompany = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteCompany>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiDeleteCompany>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getApiDeleteCompanyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiUploadCompanyRegistrationReport = (
    id: MaybeRef<string | undefined | null>,
    uploadCompanyRegistrationReportFileDto: MaybeRef<UploadCompanyRegistrationReportFileDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
uploadCompanyRegistrationReportFileDto = unref(uploadCompanyRegistrationReportFileDto);
      const formData = new FormData();
formData.append('companyRegistrationReportFile', uploadCompanyRegistrationReportFileDto.companyRegistrationReportFile)

      return fetchApiInstance<CompanyEntity>(
      {url: `/companies/${id}/uploadCompanyRegistrationReportFile`, method: 'PATCH',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getApiUploadCompanyRegistrationReportMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUploadCompanyRegistrationReport>>, TError,{id: string;data: BodyType<UploadCompanyRegistrationReportFileDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUploadCompanyRegistrationReport>>, TError,{id: string;data: BodyType<UploadCompanyRegistrationReportFileDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUploadCompanyRegistrationReport>>, {id: string;data: BodyType<UploadCompanyRegistrationReportFileDto>}> = (props) => {
          const {id,data} = props ?? {};

          return  apiUploadCompanyRegistrationReport(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUploadCompanyRegistrationReportMutationResult = NonNullable<Awaited<ReturnType<typeof apiUploadCompanyRegistrationReport>>>
    export type ApiUploadCompanyRegistrationReportMutationBody = BodyType<UploadCompanyRegistrationReportFileDto>
    export type ApiUploadCompanyRegistrationReportMutationError = ErrorType<BackendErrorEntity>

    export const useApiUploadCompanyRegistrationReport = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUploadCompanyRegistrationReport>>, TError,{id: string;data: BodyType<UploadCompanyRegistrationReportFileDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUploadCompanyRegistrationReport>>,
        TError,
        {id: string;data: BodyType<UploadCompanyRegistrationReportFileDto>},
        TContext
      > => {

      const mutationOptions = getApiUploadCompanyRegistrationReportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiApproveCompanyById = (
    id: MaybeRef<string | undefined | null>,
    params?: MaybeRef<ApiApproveCompanyByIdParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
params = unref(params);
      
      return fetchApiInstance<CompanyEntity>(
      {url: `/companies/${id}/approve`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiApproveCompanyByIdQueryKey = (id: MaybeRef<string | undefined | null>,
    params?: MaybeRef<ApiApproveCompanyByIdParams>,) => {
    return ['companies',id,'approve', ...(params ? [params]: [])] as const;
    }

    
export const getApiApproveCompanyByIdQueryOptions = <TData = Awaited<ReturnType<typeof apiApproveCompanyById>>, TError = ErrorType<BackendErrorEntity>>(id: MaybeRef<string | undefined | null>,
    params?: MaybeRef<ApiApproveCompanyByIdParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiApproveCompanyById>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiApproveCompanyByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiApproveCompanyById>>> = () => apiApproveCompanyById(id,params, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiApproveCompanyById>>, TError, TData> 
}

export type ApiApproveCompanyByIdQueryResult = NonNullable<Awaited<ReturnType<typeof apiApproveCompanyById>>>
export type ApiApproveCompanyByIdQueryError = ErrorType<BackendErrorEntity>



export function useApiApproveCompanyById<TData = Awaited<ReturnType<typeof apiApproveCompanyById>>, TError = ErrorType<BackendErrorEntity>>(
 id: MaybeRef<string | undefined | null>,
    params?: MaybeRef<ApiApproveCompanyByIdParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiApproveCompanyById>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiApproveCompanyByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



