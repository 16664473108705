<script setup lang="ts">
import type { NuxtError } from '#app';

// PROPS
const { error } = defineProps({
    error: {
        type: Object as PropType<NuxtError>,
        required: true,
    },
});

// COMPOSABLES
const isOnline = useOnline();

// DATA
const isOfflineError = computed(() => error.statusMessage === 'FetchError' && !isOnline.value);
const name = computed(() => {
    if (isOfflineError.value) {
        return 'Offline';
    } else if (error.statusCode === 404) {
        return 'Page not found';
    } else {
        return 'Internal server error';
    }
});
const statusCode = computed(() => (isOfflineError.value ? undefined : error.statusCode));
const message = computed(() => (isOfflineError.value ? 'You are currently offile, the requested page cannot be found.' : error.message));
// META
useSeoMeta({ title: () => `${name.value} - Fleequid Admin` });
</script>

<template>
    <NuxtLayout name="default">
        <UDashboardPage>
            <UDashboardPanel grow>
                <BaseDashboardNavbar :title="name" />
                <UDashboardPanelContent>
                    <UPageError :name="name" :status="statusCode" :message="message" />
                </UDashboardPanelContent>
            </UDashboardPanel>
        </UDashboardPage>
    </NuxtLayout>
</template>
